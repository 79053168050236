/* Landing.css */
.landing-container {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.landing-container-1 {
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.landing-header {
  color: white;
  z-index: 1;
}

.landing-description {
  margin-top: 1.2em;
}

.landing-footer {
  margin-top: 20px;
  color: #ddd;
}

.landing-footer-text {
  font-size: 15px;
}

@media (max-width: 768px) {
  .landing-container-1 {
    width: 90%;
    text-align: center;
  }

  .landing-title {
    font-size: 2.5rem;
  }

  .landing-description {
    font-size: 1rem;
  }
  .landing-footer-text {
    font-size: 0.9rem;
  }
}

/* Media query for small screens (mobile) */
@media (max-width: 480px) {
  .landing-title {
    font-size: 1.6rem;
  }

  .landing-description {
    font-size: 1rem;
    margin: 0.8em;
  }

  .landing-footer-text {
    font-size: 0.8rem;
  }
}
