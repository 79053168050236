/* src/components/popup/PopupWithCarousel.css */
.popup-content {
  text-align: center;
  font-family: Arial, sans-serif;
  width: 100%;
}

.popup-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 20%;
}

.popup-navigation button {
  background: none;
opacity: 100%;
  color: lightblue;
  border: none;
  padding: 1px 1px;
  border-radius: 4px;
  cursor: pointer;
}

.popup-navigation button:hover {
  color: black;
}

