/* NotFound.css */
.not-found-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #121212;
  color: #fff;
  text-align: center;
  padding: 0 1rem; /* Adds padding for smaller screens */
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.back-home-button {
  padding: 10px 20px;
  background-color: #1c1c1c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-home-button:hover {
  background-color: #333;
}

/* Media query for smaller devices */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 0.9rem;
  }

  .back-home-button {
    padding: 8px 16px;
  }
}

/* Media query for very small devices */
@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.8rem;
  }

  .back-home-button {
    padding: 6px 12px;
  }
}
